import { UpCircleOutlined } from "@ant-design/icons";
import { Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import * as number from "../../../../../utils/number";
import Category from "./Category";

function TableJournals(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.categories.length === 0) return;

    const operationsByJournal = {};

    const getType = (account) => {
      if (account.accountNumber === "40") return "achats";
      else if (account.accountNumber === "41") {
        return "ventes";
      } else {
        return "aux";
      }
    };

    props.categories.forEach((item) => {
      item.operations.forEach((operation) => {
        const type = getType(item);

        if (!operationsByJournal[operation.journal]) {
          operationsByJournal[operation.journal] = { ventes: [], achats: [], aux: [] };
        }

        const accountIsInJournals = operationsByJournal[operation.journal][type].some(
          (account) => account.accountNumberAux === item.accountNumberAux
        );

        if (!accountIsInJournals) {
          operationsByJournal[operation.journal][type].push({
            accountNumberAux: item.accountNumberAux,
            title: item.title,
            operations: [],
          });
        }

        let accountIndex = operationsByJournal[operation.journal][type].findIndex(
          (account) => account.accountNumberAux === item.accountNumberAux
        );

        if (
          type === "aux" &&
          item.title !== operationsByJournal[operation.journal][type][accountIndex].title
        ) {
          operationsByJournal[operation.journal][type].push({
            accountNumberAux: item.accountNumberAux,
            title: item.title,
            operations: [],
          });
          accountIndex = operationsByJournal[operation.journal][type].findIndex(
            (account) =>
              account.accountNumberAux === item.accountNumberAux && account.title === item.title
          );
        }

        operationsByJournal[operation.journal][type][accountIndex].operations.push(operation);
      });
    });

    if (
      !operationsByJournal[props.id]?.ventes ||
      !operationsByJournal[props.id]?.achats ||
      !operationsByJournal[props.id]?.aux
    ) {
      setData([]);
      return;
    }

    setData(
      [
        {
          id: "ach",
          label: "Achats",
          key: "achats",
          data: operationsByJournal[props.id].achats.map((account) => ({
            key: account.accountNumberAux,
            accountNumberAux: account.accountNumberAux,
            numberMissingDoc: account.operations.length,
            title: account.title,
            operations: account.operations,
            amount: account.operations.reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
          })),
        },
        {
          id: "ven",
          label: "Ventes",
          key: "ventes",
          data: operationsByJournal[props.id].ventes.map((account) => ({
            key: account.accountNumberAux,
            accountNumberAux: account.accountNumberAux,
            numberMissingDoc: account.operations.length,
            title: account.title,
            operations: account.operations,
            amount: account.operations.reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
          })),
        },
        {
          id: "au",
          label: "Compte d’attente et autres comptes",
          key: "aux",
          data: operationsByJournal[props.id].aux.map((account) => ({
            key: account.accountNumberAux,
            accountNumberAux: account.accountNumberAux,
            numberMissingDoc: account.operations.length,
            title: account.title,
            operations: account.operations,
            amount: account.operations.reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
          })),
        },
      ].map((el) => ({ ...el, totalAmount: el.data.reduce((acc, curr) => acc + curr.amount, 0) }))
    );
  }, [props.categories, props.id]);

  return (
    <>
      <Collapse
        className="type-journal-collapse"
        defaultActiveKey={["ven", "ach", "au"]}
        items={data
          .filter((element) => element.totalAmount !== 0)
          .map((el) => ({
            key: el.id,
            label: (
              <div className="journal-type-title">
                {el.label} - {number.withThousandSeparator(number.parseNum(el.totalAmount))}
              </div>
            ),
            children: (
              <Table
                className="missing-table"
                style={{ marginTop: 0 }}
                showHeader={false}
                dataSource={el.data}
                columns={props.accountColumns}
                expandable={{
                  expandedRowRender: (record) => (
                    <Category {...props} category={record} key={record._id} />
                  ),
                  expandedRowKeys: props.expandedRowKeys,
                  expandRowByClick: !props.editMode,
                  onExpandedRowsChange: (rows) => props.setExpandedRows(rows),
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <UpCircleOutlined
                      onClick={(e) => onExpand(record, e)}
                      className={expanded ? "expand-icon-down" : "expand-icon-up"}
                    />
                  ),
                }}
              />
            ),
          }))}
        expandIconPosition="end"
        bordered={false}
        expandIcon={({ isActive }) => (
          <>
            <UpCircleOutlined
              style={{ fontSize: "20px" }}
              className={isActive ? "expand-icon-down" : "expand-icon-up"}
            />{" "}
          </>
        )}
      />
    </>
  );
}

export default TableJournals;
